import React from "react";
import Footer from "../components/Footer/Footer";
import TeammateHeader from "../components/Headers/TeammateHeader/TeammateHeader";
import Navbar from "../components/Navbar/Navbar";

import Background from "../images/teammateBackground.png";
import BackgroundMobile from "../images/teammateBackgroundMobile.png";
import TeammatePhoto from "../images/Michal_Photo.png";
import Teammate from "../components/Teammate/Teammate";

const teammatePage = () => {
  return (
    <div>
      <Navbar />
      <TeammateHeader
        Name="Michal Jaroň "
        Description="Developer"
        background={Background}
        backgroundMobile={BackgroundMobile}
        TeammatePhoto={TeammatePhoto}
      />
      <article className="w-[100%] h-auto flex items-center justify-center bg-white">
        <Teammate
          paragraph1="i’m a passionate game engine developer with many years of experience working with graphics APIs and core designs. 
          My past experiences include working with Unreal Engine, Unity3D, porting games to PlayStation, Xbox, and Nintento Switch and being a graphics 
          engineer at Bohemia Interactive working on Enfusion engine, a custom make game engine for Arma Reforger. Some hobbies of mine include tinkering with electronics,
           rock climbing, restoring old mechanical watches, military reenactment, and airsoft. i also love atmospheric games, adventure games, and shooter games."
          paragraph2="i grew up playing these types of games, memorably, Age of Empires, Star Wars Jedi Knight II: Jedi Outcast, Return to Castle Wolfenstein and Vietcong. 
    My role in intermeta is to develop core features, render systems and supervise developers on code standards and making sure the code is efficient and fits our systems.
     I am excited to work with intermeta and develop new technologies with our intermetability software."
        />
      </article>
      <Footer />
    </div>
  );
};

export default teammatePage;
